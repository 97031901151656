<template>
  <div>
    <div v-if="youtubeId">
      <img :src="youtubeEmbedUrl" style='width:100%;' border="0">
    </div>
    <div v-else>
      <!-- <p>No YouTube image available.</p> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    youtubeId: {
      type: String,
      default: null,
    },
  },
  computed: {
    youtubeEmbedUrl() {
      if (this.youtubeId) {
        return `https://img.youtube.com/vi/${this.youtubeId}/maxresdefault.jpg`;
      }
      return null;
    },
  },
};
</script>

<style>
.embed-container {
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0; 
  overflow: hidden; 
  max-width: 100%; 
} 
.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
}
</style>