<template>
  <div>
    <div v-if="youtubeId" class="embed-container">
      <iframe
        :src="youtubeEmbedUrl"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
    <div v-else>
      <!-- <p>No YouTube video available.</p> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    youtubeId: {
      type: String,
      default: null,
    },
  },
  computed: {
    youtubeEmbedUrl() {
      if (this.youtubeId) {
        return `https://www.youtube.com/embed/${this.youtubeId}`;
      }
      return null;
    },
  },
};
</script>

<style>
.embed-container {
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0; 
  overflow: hidden; 
  max-width: 100%; 
} 
.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
}
</style>