<template>
  <div>
    {{ displayTitle }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      siteTitle: this.$store.state.siteTitle,
    };
  },
  computed: {
    displayTitle() {
      var hostName = window.location.host;
      var domain = hostName;
    
      if (hostName != null) {
        var parts = hostName.split('.').reverse();

        if (parts != null && parts.length > 1) {
            domain = parts[1] + '.' + parts[0];
                
            if (hostName.toLowerCase().indexOf('.co.kr') != -1 && parts.length > 2) {
              domain = parts[2] + '.' + domain;
            }
        }
      }
      return this.siteTitle || domain.toUpperCase();
    },
  },
};
</script>